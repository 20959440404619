import React, { ReactElement } from "react";
import { BottleType } from "../ModelTypes";
import GoldMedal from "../shared/images/GoldMedal.png";
import SilverMedal from "../shared/images/SilverMedal.png";
import BronzeMedal from "../shared/images/BronzeMedal.png";
import BottleItem from "./BottleItem";

export default function HorizontalBottles(props: {
  bottles: BottleType[];
  showRanking: boolean;
}): ReactElement {
  const { bottles, showRanking } = props;

  return (
    <ul className="flex overflow-x-auto py-2">
      {bottles.map((b, i) => (
        <li className="flex-none mr-2" style={{ width: "8rem" }} key={b.id}>
          {showRanking && (
            <div className="p-1 flex justify-center h-8">
              {i < 3 && (
                <div>
                  <img
                    src={
                      i === 0 ? GoldMedal : i === 1 ? SilverMedal : BronzeMedal
                    }
                    alt={`${i + 1}位`}
                    className="w-4"
                  />
                </div>
              )}
              {i >= 3 && <div>{i}</div>}
            </div>
          )}
          <BottleItem bottle={b} imageWidth="8rem" imageHeight="9.6rem" />
        </li>
      ))}
    </ul>
  );
}
