import React, { ReactElement, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { DrinkNoteType, FileResourceType, BottleType } from "../ModelTypes";
import LoadingImage from "../shared/images/Loading.gif";
import DrinkNoteItem from "./DrinkNoteItem";
import { getRequest } from "../utils/ApiClient";

type ResponseType = DrinkNoteType & {
  file_resources: FileResourceType[];
};

export default function DrinkNoteList(props: {
  bottle: BottleType;
}): ReactElement {
  const { bottle } = props;
  const [drinkNotes, setDrinkNotes] = useState<ResponseType[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const loadMore = async (page) => {
    const { result } = await getRequest(
      `/anonymous_api/bottles/${bottle.id}/drink_notes`,
      {
        page,
      }
    );
    if (result) {
      const data: ResponseType[] = result;
      if (data.length < 1) {
        setHasMore(false);
        return;
      }
      setDrinkNotes([...drinkNotes, ...data]);
    }
  };

  const loader = (
    <div className="flex justify-center w-full mt-5" key={0}>
      <div>
        <img src={LoadingImage} alt="Loading.." />
      </div>
    </div>
  );

  if (!hasMore && drinkNotes.length === 0) {
    return (
      <div className="text-gray-400 text-sm">
        テイスティングコメントはありません
      </div>
    );
  }

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={loadMore}
      hasMore={hasMore}
      loader={loader}
    >
      {drinkNotes.map((dn) => (
        <div key={dn.id} className="mb-3">
          <DrinkNoteItem drinkNote={dn} />
        </div>
      ))}
    </InfiniteScroll>
  );
}
