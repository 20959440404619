import React, { ReactElement, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import HorizontalBottles from "../bottles/HorizontalBottles";
import { BottleType, ShelfType } from "../ModelTypes";
import LoadingImage from "../shared/images/Loading.gif";
import { getRequest } from "../utils/ApiClient";

type ResponseType = ShelfType & {
  bottles: BottleType[];
};

export default function ShelfList(props: { userId: number }): ReactElement {
  const { userId } = props;
  const [shelves, setShelves] = useState<ResponseType[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const loadMore = async (page) => {
    const { result } = await getRequest(
      `/anonymous_api/users/${userId}/shelves`,
      {
        page,
        q: { bottle_exists: 1 },
      }
    );
    if (result) {
      const data: ResponseType[] = result;
      if (data.length < 1) {
        setHasMore(false);
        return;
      }
      setShelves([...shelves, ...data]);
    }
  };

  const loader = (
    <div className="flex justify-center w-full mt-16" key={0}>
      <div>
        <img src={LoadingImage} alt="Loading.." />
      </div>
    </div>
  );

  if (!hasMore && shelves.length === 0) {
    return (
      <div className="text-center text-gray-500">
        ボトルがまだ登録されていません
      </div>
    );
  }

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={loadMore}
      hasMore={hasMore}
      loader={loader}
    >
      {shelves.map((s) => (
        <div key={s.id} className="mb-3">
          <div className="border-b border-secondary font-bold ml-1">
            {s.name}
          </div>
          <HorizontalBottles
            bottles={s.bottles}
            showRanking={s.mode === "ranking_mode"}
          />
        </div>
      ))}
    </InfiniteScroll>
  );
}
