import React, { ReactElement, useState } from "react";
import Modal from "react-modal";
import { BottleType } from "../ModelTypes";
import DrinkNoteList from "../drink_notes/DrinkNoteList";
import BottleImage from "./BottleImage";
import BottleCard from "./BottleCard";

Modal.setAppElement("body");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    height: "70%",
    maxWidth: 700,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
};

export default function BottleItem(props: {
  bottle: BottleType;
  imageWidth: string;
  imageHeight: string;
}): ReactElement {
  const { bottle, imageWidth, imageHeight } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <React.Fragment>
      {showModal && (
        <Modal
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div>
            <BottleCard bottle={bottle} />
            <div className="bg-gray-100 absolute inset-x-0 mt-2 text-xs py-1 px-3 text-gray-500">
              テイスティングコメント
            </div>
            <div className="mt-10">
              <DrinkNoteList bottle={bottle} />
            </div>
          </div>
        </Modal>
      )}
      <div
        role="button"
        tabIndex={0}
        className="cursor-pointer"
        onClick={() => setShowModal(true)}
      >
        <BottleImage
          bottle={bottle}
          imageHeight={imageHeight}
          imageWidth={imageWidth}
        />
        <div className="text-sm">{bottle.name}</div>
      </div>
    </React.Fragment>
  );
}
