import { serialize } from "object-to-formdata";
import qs from "qs";
import axios from "axios";

async function execRequest(config) {
  try {
    const res = await axios(config);
    return { result: res.data };
  } catch (error) {
    // タイムアウトの場合
    const isTimeout = error.code === "ECONNABORTED";
    if (isTimeout) {
      // alert("タイムアウトしました。しばらく時間をおいて再実行してください。")
      return {};
    }
    // ネットワークエラーの場合
    if (!error.response) {
      return {};
    }
    const { data } = error.response;
    if (data.message && Array.isArray(data.message)) {
      window.alert(data.message.join(","));
    }
    return { error: data };
  }
}

export async function getRequest(url, params = {}) {
  const { result, error } = await execRequest({
    method: "get",
    url,
    params,
    paramsSerializer: (p) => qs.stringify(p, { arrayFormat: "brackets" }),
  });
  return { result, error };
}

export async function putRequest(url, data = {}) {
  const { result, error } = await execRequest({
    method: "put",
    url,
    data: serialize(data),
  });
  return { result, error };
}

export async function postRequest(url, data = {}) {
  const { result, error } = await execRequest({
    method: "post",
    url,
    data: serialize(data),
  });
  return { result, error };
}

export async function deleteRequest(url, data = {}) {
  const { result, error } = await execRequest({
    method: "delete",
    url,
    data: serialize(data),
  });
  return { result, error };
}
