import React, { ReactElement } from "react";
import Gallery from "react-grid-gallery";
import { format, parseISO } from "date-fns";
import ja from "date-fns/locale/ja";
import { DrinkNoteType, FileResourceType } from "../ModelTypes";

export default function DrinkNoteItem(props: {
  drinkNote: DrinkNoteType & { file_resources: FileResourceType[] };
}): ReactElement {
  const { drinkNote } = props;

  return (
    <div className="border-b p-2">
      <div className="text-xs text-gray-500">
        {format(parseISO(drinkNote.created_at), "yyyy年MM月dd日", {
          locale: ja,
        })}
      </div>
      <div className="text-sm whitespace-pre-wrap">{drinkNote.body}</div>
      {drinkNote.file_resources.length > 0 && (
        <div className="mt-4 overflow-auto">
          <Gallery
            images={drinkNote.file_resources.map((fr) => {
              return {
                src: fr.url,
                thumbnail: fr.url,
                thumbnailWidth: 300,
                thumbnailHeight: 230,
              };
            })}
            enableImageSelection={false}
            thumbnailStyle={() => {
              return { objectFit: "cover", width: "100%", height: "100%" };
            }}
          />
        </div>
      )}
    </div>
  );
}
