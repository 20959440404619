import React, { ReactElement } from "react";
import { BottleType } from "../ModelTypes";
import BottleImage from "./BottleImage";

export default function BottleCard(props: {
  bottle: BottleType;
}): ReactElement {
  const { bottle } = props;

  return (
    <div>
      <div className="font-bold py-1">{bottle.name}</div>
      <div className="flex">
        <div className="flex-none" style={{ width: "5rem" }}>
          <BottleImage bottle={bottle} imageWidth="5rem" imageHeight="6rem" />
        </div>
        <div className="flex-grow text-sm p-2 whitespace-pre-wrap">
          {bottle.note}
        </div>
      </div>
    </div>
  );
}
