import React, { ReactElement, useState } from "react";
import { BottleType } from "../ModelTypes";
import BottleEmptyImage from "../shared/images/BottleEmpty.png";

export default function BottleImage(props: {
  bottle: BottleType;
  imageWidth: string;
  imageHeight: string;
}): ReactElement {
  const { bottle, imageWidth, imageHeight } = props;

  return (
    <div>
      <img
        src={
          bottle.file_resources.length > 0
            ? bottle.file_resources[0].url
            : BottleEmptyImage
        }
        alt="ボトル画像"
        className="object-cover rounded-md"
        style={{ width: imageWidth, height: imageHeight }}
      />
    </div>
  );
}
